<template>
  <div v-if="page" class="page">
    <div class="grid-x align-center">
      <div class="cell small-10 medium-8">
        <router-link class="link" :to="{ name: 'terms-conditions' }">Terms and Conditions</router-link>
        <router-link class="link" :to="{ name: 'privacy-policy' }">Privacy Policy</router-link>
        <h1 class="title">{{ page.title }}</h1>
        <div class="content" v-html="page.content"/>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapMutations } from 'vuex';

  export default {
    props: {
      slug: String
    },
    metaInfo: {
      title: 'Black Tower',
      titleTemplate: null
    },
    components: {

    },
    mounted() {
      this.setNavigationPadding();
      this.grabPage();
    },
    destroyed() {
      this.setNavigationPadding(false);
    },
    methods: {
      grabPage() {
        this.fetchPage({slug: this.slug, template: 'legal'})
          .then(() => {
          });
      },
      ...mapActions(['fetchPage']),
      ...mapMutations(['setNavigationPadding'])
    },
    computed: {
      page() {
        return this.$store.getters.getPage(this.slug);
      }
    },
    watch: {
      slug() {
        this.grabPage();
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import 'foundation-sites/scss/foundation.scss';
  @import '@/assets/scss/_settings';
  .page {
    padding: vw-relative(30, 320) 0;
    @include breakpoint(medium) {
      padding: vw-relative(50) 0;
    }
  }
  .link {
    font: 10px/1.2 Brown;
    text-transform: uppercase;
    display: inline-block;
    margin-bottom: 1.5em;
    color: #E4DFDF;
    margin-right: 2em;
    @include breakpoint(medium) {
      font-size: vw-relative(16);
    }
    &.router-link-active {
      color: #000;
    }
  }
  .title {
    font: bold 20px/1 Brown;
    text-transform: uppercase;
    margin-bottom: 1em;
    @include breakpoint(medium) {
      font-size: vw-relative(26);
    }
  }
  .content {
    font: 300 12px/1.2 Brown;
    @include breakpoint(medium) {
      font-size: vw-relative(14);
    }
  }
</style>
